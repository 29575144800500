<template>
  <div class="table-wrap no-wrap-css">
    <div ref="table-scroll" class="table-scroll">
      <table class="tbl tbl-export" :class="css">
        <thead v-if="rows.length">
          <tr>
            <th
              v-if="rowsCollapsible"
              class="short-column sticky-column-header subRowCollapsible"
            ></th>
            <th
              v-for="(header, key, i) in headers"
              :ref="`${header.name}_${i}`"
              :key="i"
              :style="colWidthStyle(header)"
              :class="[header.isSticky ? 'sticky-column sticky-column-header' : '']"
              @mouseover="(el) => handleMouseOver(el, header.name, `${header.name}_${i}`)"
              @mouseleave="handleMouseLeave"
            >
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <tbody class="body-half-screen">
          <tr
            v-for="(item, i) in rows"
            :id="i"
            :key="i"
            :class="[
              { 'sub-row': !!item.subRow },
              `${item.open ? 'highlightBackgroundParent' : ''}`,
            ]"
          >
            <td
              v-if="rowsCollapsible"
              :class="[
                item.subRow ? 'sub-row' : '',
                'short-column sticky-column subRowCollapsible',
                item.open && !item.subRowLoading ? 'highlightBorder' : '',
              ]"
            >
              <div class="relative">
                <div v-if="item.open && !item.subRowLoading" class="chevron-arrow-border"></div>
                <font-awesome-icon
                  v-if="item.timesExposed > 1 && !item.subRow && !item.subRowLoading"
                  :icon="['fa', item.open ? 'chevron-down' : 'chevron-right']"
                  class="pointer"
                  @click="$emit('row-click', { data: item, index: i })"
                />
                <b-loading-spinner v-if="item.open && item.subRowLoading" class="touches-loader" />
              </div>
            </td>
            <td
              v-for="(header, key, j) in headers"
              :key="j"
              :ref="`${readVal(item, header)}_${i}_${header.name}`"
              class="tooltip_data"
              :style="colWidthStyle(header)"
              :class="[
                header.isSticky ? 'sticky-column' : '',
                !!item.subRow ? 'sub-row-column-highlight' : '',
              ]"
              @mouseover="
                (el) =>
                  handleMouseOver(
                    el,
                    getItemTooltipVal(item, header),
                    `${readVal(item, header)}_${i}_${header.name}`,
                    true
                  )
              "
              @mouseleave="handleMouseLeave"
            >
              {{ readVal(item, header) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div :style="activeEl.style" class="tooltipText">
      {{ activeEl.text }}
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import numeral from 'numeral';
import { isBlank } from 'adready-api/helpers/common';
import * as util from '~/util/utility-functions';
import { formatDate } from '~/util/apiDateFormat';

export default {
  components: {
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  props: {
    rows: {
      type: Array,
      required: false,
      default: () => [],
    },
    headers: {
      type: Array,
      required: false,
      default: () => [],
    },
    rowToScrollTo: {
      type: String,
      required: false,
      default: '',
    },
    rowsCollapsible: {
      required: false,
      type: Boolean,
      default: false,
    },
    css: {
      type: String,
      required: false,
      default: '',
    },
    isPageView: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      activeEl: { style: { display: 'none' }, text: '' },
    };
  },
  computed: {
    incReachMetricsData: get('dashboard/incReachMetricsData'),
  },
  mounted() {
    this.scrollToRow(this.rowToScrollTo);
    // console.log('rows: ', this.rows);
  },
  methods: {
    handleMouseOver(el, text, refKey, isRow = false) {
      const elementPos = this.$refs[refKey][0];
      const tableElement = document.getElementsByClassName('table-scroll');
      const addScrollTop = isRow && !this.isPageView ? 170 : 35;
      if (!elementPos) {
        return;
      }
      this.activeEl = {
        style: {
          top: `${elementPos.offsetParent.offsetTop - tableElement[0].scrollTop + addScrollTop}px`,
          left: `${elementPos.offsetLeft - tableElement[0].scrollLeft + 40}px`,
          display: 'block',
        },
        text,
      };
    },
    handleMouseLeave() {
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
    colWidthStyle(header) {
      let styleString = `width: ${header.width ? header.width : '84px'} !important;`;
      if (header.isSticky) {
        styleString += ` left: ${header.stickyWidth}px`;
      }
      return styleString;
    },
    getDataLength(val) {
      return `${val}`.length;
    },
    getItemTooltipVal(item, header) {
      let val = item[header.key];
      if (header.key === 'incrementalReachPercentage') {
        val = item?.incrementalReachPercentage || 0;
      }
      return val;
    },
    readVal(item, header) {
      let val = item[header.key];
      const { formatter } = header;
      if (header.key === 'incrementalReachPercentage') {
        return parseFloat(item?.incrementalReachPercentage.toFixed(2));
      }
      if (val && formatter) {
        if (formatter.type === 'number') {
          const isValueInfinity = !isBlank(header.infinityKey) && item[header.infinityKey];
          val = isValueInfinity ? 'NA' : numeral(val).format(formatter.format);
        } else if (formatter.type === 'datetime') {
          val = formatDate(val, formatter.format);
        } else if (formatter.type === 'hour') {
          val = util.formatHour(val);
        }
      }

      return val;
    },
    scrollToRow(rowId) {
      const row = this.$refs['table-scroll'].querySelector(`table tbody tr[id="${rowId}"]`);
      this.$refs['table-scroll'].scrollTo(0, row.offsetTop - row.offsetHeight - 5);
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen {
  * {
    box-sizing: border-box;
  }
  table.tbl-export {
    & thead {
      padding-bottom: unset;
      border-bottom: unset;
    }
    border-collapse: separate;
    & td.short-column.sticky-column.subRowCollapsible {
      width: 26px !important;
      overflow: unset;
    }
    & th.short-column.sticky-column-header.subRowCollapsible {
      width: 26px !important;
    }
  }
  table.tbl-export .sticky-column {
    z-index: 1;
    background-color: #212429;
    &.subRowCollapsible {
      left: 0px;
    }
    &.sub-row-column-highlight {
      background-color: #191919;
    }
    &-header {
      background: var(--primarycolor);
      z-index: 5;
      &.subRowCollapsible {
        left: 0px;
      }
    }
  }
  .table-wrap {
    .tbl {
      thead {
        z-index: 4;
      }
    }
  }
  .table-wrap.no-wrap-css .tbl tbody {
    overflow: unset;
  }
  .table-wrap.no-wrap-css .tbl thead tr:hover th {
    white-space: nowrap;
  }
  .table-wrap.no-wrap-css .tbl tbody tr:hover {
    & td.sticky-column {
      background-color: #373a44;
      &:not(.sticky-column-header) {
        // top: 7px;
      }
    }
    z-index: 3;
    white-space: nowrap;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  .body-half-screen {
    .relative {
      position: relative;
    }
    tr {
      position: relative;
    }
    tr td.highlightBorder {
      border-right: 2px solid var(--primarycolor);
      left: 1px;
      z-index: 3;
      height: 3em;
    }
  }
  .touches-loader {
    height: 1em;
    top: -10px;
    left: -47px;
    transform: scale(0.7);
    width: 82px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}
.pointer {
  cursor: pointer;
}
.chevron-arrow-border {
  position: absolute;
  width: 2px;
  height: 3em;
  background: var(--primarycolor);
  bottom: -17px;
  left: 16px;
}
.tooltipText {
  position: absolute;
  z-index: 999;
  width: auto;
  font-size: 12px;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
  text-align: center;
  border-radius: 3px;
  transition: ease 0.3s;
  line-height: 1.2em;
  transform-origin: bottom center;
  padding: 5px;
  display: none;
}
.tooltip_data:hover .tooltipText {
  display: block;
}
.highlightBackgroundParent {
  background-color: #191919;
  border-bottom: none;
  > .sticky-column {
    background-color: #191919;
    &:nth-child(n + 2):nth-child(-n + 5) {
      background-color: #191919;
      &:hover {
        background-color: #373a44;
      }
    }
    &:hover {
      background-color: #373a44;
    }
  }
  &:hover {
    background-color: #373a44;
  }
}
</style>
